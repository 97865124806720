/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    code: "code",
    h2: "h2",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "vertical-alignment",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#vertical-alignment",
    "aria-label": "vertical alignment permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Vertical Alignment"), "\n", React.createElement(_components.p, null, "Utilities for controlling the vertical alignment of an inline or table-cell box."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "React props"), React.createElement(_components.th, null, "CSS Properties"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "verticalAlign={value}")), React.createElement(_components.td, null, React.createElement(_components.code, null, "vertical-align: {value};"))))), "\n", React.createElement(_components.h2, {
    id: "baseline",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#baseline",
    "aria-label": "baseline permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Baseline"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "verticalAlign=\"baseline\""), " to align the baseline of an element with the baseline of its parent."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=light-blue"
  }, "<>\n  <template preview>\n    <x.div lineHeight=\"none\" position=\"relative\">\n      <x.span w={0} h={8} display=\"inline-block\" verticalAlign=\"baseline\">\n        <x.span\n          position=\"absolute\"\n          top={0}\n          borderColor=\"light-blue-300\"\n          borderStyle=\"dashed\"\n          borderWidth=\"1 0\"\n          w={1}\n          h={8}\n        />\n        <x.span\n          position=\"absolute\"\n          top={0}\n          borderColor=\"light-blue-300\"\n          borderStyle=\"dashed\"\n          borderWidth=\"1 0\"\n          w={1}\n          h={4}\n        />\n      </x.span>\n      <x.span\n        position=\"relative\"\n        zIndex={10}\n        color=\"light-blue-700\"\n        fontWeight=\"medium\"\n      >\n        Computers have lots of memory but no imagination.\n      </x.span>\n    </x.div>\n  </template>\n  <x.span display=\"inline-block\" verticalAlign=\"baseline\">\n    ...\n  </x.span>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "top",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#top",
    "aria-label": "top permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Top"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "verticalAlign=\"top\""), " to align the top of an element and its descendants with the top of the entire line."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=emerald"
  }, "<>\n  <template preview>\n    <x.div lineHeight=\"none\" position=\"relative\">\n      <x.span w={0} h={8} display=\"inline-block\" verticalAlign=\"top\">\n        <x.span\n          position=\"absolute\"\n          top={0}\n          borderColor=\"emerald-300\"\n          borderStyle=\"dashed\"\n          borderWidth=\"1 0\"\n          w={1}\n          h={8}\n        />\n        <x.span\n          position=\"absolute\"\n          top={0}\n          borderColor=\"emerald-300\"\n          borderStyle=\"dashed\"\n          borderWidth=\"1 0\"\n          w={1}\n          h={4}\n        />\n      </x.span>\n      <x.span\n        position=\"relative\"\n        zIndex={10}\n        color=\"emerald-700\"\n        fontWeight=\"medium\"\n      >\n        Computers have lots of memory but no imagination.\n      </x.span>\n    </x.div>\n  </template>\n  <x.span display=\"inline-block\" verticalAlign=\"top\">\n    ...\n  </x.span>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "middle",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#middle",
    "aria-label": "middle permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Middle"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "verticalAlign=\"middle\""), " to align the middle of an element with the baseline plus half the x-height of the parent."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=purple"
  }, "<>\n  <template preview>\n    <x.div lineHeight=\"none\" position=\"relative\">\n      <x.span w={0} h={8} display=\"inline-block\" verticalAlign=\"middle\">\n        <x.span\n          position=\"absolute\"\n          top={0}\n          borderColor=\"purple-300\"\n          borderStyle=\"dashed\"\n          borderWidth=\"1 0\"\n          w={1}\n          h={8}\n        />\n        <x.span\n          position=\"absolute\"\n          top={0}\n          borderColor=\"purple-300\"\n          borderStyle=\"dashed\"\n          borderWidth=\"1 0\"\n          w={1}\n          h={4}\n        />\n      </x.span>\n      <x.span\n        position=\"relative\"\n        zIndex={10}\n        color=\"purple-700\"\n        fontWeight=\"medium\"\n      >\n        Computers have lots of memory but no imagination.\n      </x.span>\n    </x.div>\n  </template>\n  <x.span display=\"inline-block\" verticalAlign=\"middle\">\n    ...\n  </x.span>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "bottom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bottom",
    "aria-label": "bottom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Bottom"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "verticalAlign=\"bottom"), " to align the bottom of an element and its descendants with the bottom of the entire line."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=red"
  }, "<>\n  <template preview>\n    <x.div lineHeight=\"none\" position=\"relative\">\n      <x.span w={0} h={8} display=\"inline-block\" verticalAlign=\"bottom\">\n        <x.span\n          position=\"absolute\"\n          top={0}\n          borderColor=\"red-300\"\n          borderStyle=\"dashed\"\n          borderWidth=\"1 0\"\n          w={1}\n          h={8}\n        />\n        <x.span\n          position=\"absolute\"\n          top={0}\n          borderColor=\"red-300\"\n          borderStyle=\"dashed\"\n          borderWidth=\"1 0\"\n          w={1}\n          h={4}\n        />\n      </x.span>\n      <x.span\n        position=\"relative\"\n        zIndex={10}\n        color=\"red-700\"\n        fontWeight=\"medium\"\n      >\n        Computers have lots of memory but no imagination.\n      </x.span>\n    </x.div>\n  </template>\n  <x.span display=\"inline-block\" verticalAlign=\"bottom\">\n    ...\n  </x.span>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "text-top",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#text-top",
    "aria-label": "text top permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Text Top"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "verticalAlign=\"text-top\""), " to align the top of an element with the top of the parent element's font."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=amber"
  }, "<>\n  <template preview>\n    <x.div lineHeight=\"none\" position=\"relative\">\n      <x.span w={0} h={8} display=\"inline-block\" verticalAlign=\"text-top\">\n        <x.span\n          position=\"absolute\"\n          top={0}\n          borderColor=\"amber-300\"\n          borderStyle=\"dashed\"\n          borderWidth=\"1 0\"\n          w={1}\n          h={8}\n        />\n        <x.span\n          position=\"absolute\"\n          top={0}\n          borderColor=\"amber-300\"\n          borderStyle=\"dashed\"\n          borderWidth=\"1 0\"\n          w={1}\n          h={4}\n        />\n      </x.span>\n      <x.span\n        position=\"relative\"\n        zIndex={10}\n        color=\"amber-700\"\n        fontWeight=\"medium\"\n      >\n        Computers have lots of memory but no imagination.\n      </x.span>\n    </x.div>\n  </template>\n  <x.span display=\"inline-block\" verticalAlign=\"text-top\">\n    ...\n  </x.span>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "text-bottom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#text-bottom",
    "aria-label": "text bottom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Text Bottom"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "verticalAlign=\"text-bottom\""), " to align the bottom of an element with the bottom of the parent element's font."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=pink"
  }, "<>\n  <template preview>\n    <x.div lineHeight=\"none\" position=\"relative\">\n      <x.span w={0} h={8} display=\"inline-block\" verticalAlign=\"text-bottom\">\n        <x.span\n          position=\"absolute\"\n          top={0}\n          borderColor=\"pink-300\"\n          borderStyle=\"dashed\"\n          borderWidth=\"1 0\"\n          w={1}\n          h={8}\n        />\n        <x.span\n          position=\"absolute\"\n          top={0}\n          borderColor=\"pink-300\"\n          borderStyle=\"dashed\"\n          borderWidth=\"1 0\"\n          w={1}\n          h={4}\n        />\n      </x.span>\n      <x.span\n        position=\"relative\"\n        zIndex={10}\n        color=\"pink-700\"\n        fontWeight=\"medium\"\n      >\n        Computers have lots of memory but no imagination.\n      </x.span>\n    </x.div>\n  </template>\n  <x.span display=\"inline-block\" verticalAlign=\"text-bottom\">\n    ...\n  </x.span>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "responsive",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#responsive",
    "aria-label": "responsive permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Responsive"), "\n", React.createElement(_components.p, null, "To control the vertical alignment of an element at a specific breakpoint, use responsive object notation. For example, adding the property ", React.createElement(_components.code, null, "verticalAlign={{ md: \"text-bottom\" }}"), " to an element would apply the ", React.createElement(_components.code, null, "verticalAlign=\"text-bottom\""), " utility at medium screen sizes and above."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<x.div verticalAlign={{ md: 'text-bottom' }}>{/* ... */}</x.div>\n")), "\n", React.createElement(_components.p, null, "For more information about xstyled's responsive design features, check out ", React.createElement(_components.a, {
    href: "/docs/responsive-design/"
  }, "Responsive Design"), " documentation."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
